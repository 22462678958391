import styled from "styled-components"

export const ColoredDot = styled.div`
  display: inline-block;
  background: ${props => props.color};
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  margin-right: 0.25em;
`
