import qs from "query-string"
import { isArray } from "util"

const setQueryStringWithoutPageReload = qsValue => {
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue
  window.history.pushState({ path: newurl }, "", newurl)
}

function isArrayGuard(x: any): x is Array<any> {
  return isArray(x)
}

export const getQueryStringValue = (
  key,
  queryString = window.location.search
): string => {
  const values = qs.parse(queryString)

  if (values[key] && isArrayGuard(values[key])) {
    return (values[key] as Array<string>).join(",")
  }

  return values[key] as string
}

export const setQueryStringValue = (
  key,
  value,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString)
  const newQsValue = qs.stringify({
    ...values,
    [key]: value,
  })
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}
