import * as React from "react"
import { Table } from "semantic-ui-react"

import { FitoEvent } from "../typings/FitoEvent"
import { TrainerDisplay } from "./TrainerDisplay"

interface IProps {
  event: FitoEvent
}

export const EventTableTeacherCell: React.FC<IProps> = ({ event }) => {
  return (
    <Table.Cell className="teacher-name">
      {event.trainers.map(trainer => (
        <TrainerDisplay trainer={trainer} key={trainer.id} />
      ))}
    </Table.Cell>
  )
}
