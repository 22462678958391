import React from "react"
import { FitoEvent } from "../typings/FitoEvent"
import { TableCell, Popup, Icon } from "semantic-ui-react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

interface Props {
  event: FitoEvent
}

const EventTableBookingWindowCell: React.FC<Props> = props => {
  if (
    !props.event.bookingWindowEnabled ||
    props.event.bookingWindowStartHours <= 0
  ) {
    return <TableCell></TableCell>
  }

  const bookingOpening = dayjs(props.event.startDateTime).subtract(
    props.event.bookingWindowStartHours,
    "h"
  )

  return (
    <TableCell>
      <span>{bookingOpening.fromNow()}</span>
      <Popup
        content={bookingOpening.format("dddd DD/MM - LT")}
        trigger={
          <Icon name="question circle outline" style={{ marginLeft: ".5em" }} />
        }
      />
    </TableCell>
  )
}

export default EventTableBookingWindowCell
