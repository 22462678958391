import dayjs from "dayjs"

export const START_TIME = dayjs()
export const END_TIME = dayjs().add(6, 'months')
export const SMALL_WAITLIST = 5

// Feature flags
export const BOOKING_ENABLED = false
export const BOOKING_WINDOW_VISIBLE = false
export const CLASS_TYPES_FILTER = false
