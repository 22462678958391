import React, { useState } from "react"
import { Grid, Header } from "semantic-ui-react"

import SEO from "../components/seo"
import EventTable from "../components/EventTable"
import { Layout } from "../layouts"
import { TableControls, initialValues } from "../components/TableControls"

const IndexPage: React.FC = () => {
  const [filterData, saveFilterData] = useState(initialValues)

  return (
    <Layout>
      <>
        <SEO title="Liste des cours" />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header as="h2">Liste des cours et workshops</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <TableControls onSubmit={saveFilterData} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <EventTable {...filterData} />
          </Grid.Column>
        </Grid.Row>
      </>
    </Layout>
  )
}

export default IndexPage
