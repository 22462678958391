import * as React from "react"
import { Table, Popup, Icon } from "semantic-ui-react"
import { FitoEvent } from "../typings/FitoEvent"
import { ColoredDot } from "./ColoredDot"

interface IProps {
  event: FitoEvent
}

export const EventTableEventNameCell: React.FC<IProps> = ({ event }) => {
  return (
    <Table.Cell className="event-name">
      <ColoredDot color={event.color} />
      <span style={{ marginRight: ".25em" }}>{event.name}</span>
      {event.descriptions[0]?.text?.length > 0 && (
        <Popup
          mouseEnterDelay={500}
          mouseLeaveDelay={500}
          wide="very"
          position="right center"
          content={
            <div
              dangerouslySetInnerHTML={{
                __html: event.descriptions[0].text,
              }}
            />
          }
          trigger={<Icon name="question circle outline" />}
        />
      )}
    </Table.Cell>
  )
}
