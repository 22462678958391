import React from "react"
import { FitoEvent } from "../typings/FitoEvent"
import {
  Table,
  Label,
  Icon,
  SemanticCOLORS,
  LabelGroup,
} from "semantic-ui-react"

export default function EventTableBookingCell({ event }: { event: FitoEvent }) {
  const remainingSeats = event.seats - event.seatsBooked
  const warningSeats = Math.floor(event.seats / 2)
  const isUserInWaitlist =
    event.bookings.length > 0 && event.bookings[0].status === "WaitingList"
  const isUserBooked =
    event.bookings.length > 0 && event.bookings[0].status === "Booked"

  const isNearlyFull =
    !isUserInWaitlist &&
    !isUserBooked &&
    remainingSeats < warningSeats &&
    remainingSeats > 0
  const isFull = !isUserInWaitlist && !isUserBooked && remainingSeats <= 0

  let labelColor: SemanticCOLORS = "teal"
  if (isNearlyFull) {
    labelColor = "orange"
  } else if (isFull) {
    labelColor = "red"
  }

  return (
    <Table.Cell>
      {event.status === "Cancelled" && <Label color="grey">Annulé</Label>}
      {event.status !== "Cancelled" && (
        <LabelGroup>
          {isUserInWaitlist && (
            <Label color="blue">
              <Icon name="clock" title="Vous êtes dans la liste d'attente" />
              En file d&apos;attente
            </Label>
          )}
          {isUserBooked && (
            <Label color="green">
              <Icon name="check" title="Vous avez votre place" />
              Réservé
            </Label>
          )}
          {!isUserBooked && !isUserInWaitlist && (
            <Label basic color={labelColor}>
              <Icon name="users" />
              {event.seatsBooked}/{event.seats}
            </Label>
          )}
          {!isUserBooked && !isUserInWaitlist && event.seatsWaiting > 0 && (
            <Label basic color="grey">
              <Icon name="hourglass half" />
              {event.seatsWaiting}
            </Label>
          )}
        </LabelGroup>
      )}
    </Table.Cell>
  )
}
