import * as React from "react"
import { FitoTrainer } from "../typings/FitoTrainer"
import { Popup, Icon, Image } from "semantic-ui-react"

interface IProps {
  trainer: FitoTrainer
}

export const TrainerDisplay: React.FC<IProps> = ({ trainer }) => {
  const popupMemo = React.useMemo(() => {
    if (!trainer.descriptions[0]) {
      return null
    }

    return (
      <Popup
        mouseEnterDelay={500}
        mouseLeaveDelay={500}
        wide="very"
        position="right center"
        header={trainer.name}
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: trainer.descriptions[0].text,
            }}
          />
        }
        trigger={
          <Icon
            name="question circle outline"
            style={{ marginLeft: ".25em" }}
          />
        }
      />
    )
  }, [trainer])

  return (
    <div className="teacher">
      {trainer.imageUrl && (
        <Image
          src={trainer.imageUrl}
          avatar
          style={{ marginRight: ".5em" }}
          alt={trainer.name}
        />
      )}
      {trainer.name}
      {trainer.descriptions[0]?.text?.length > 0 && popupMemo}
    </div>
  )
}
