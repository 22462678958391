import React, { useContext } from "react"
import {
  Table,
  Input,
  TableCell,
  TableRow,
  Dimmer,
  Loader,
  PlaceholderLine,
  Placeholder,
  Dropdown,
} from "semantic-ui-react"
import styled from "styled-components"

import dayjs from "dayjs"
import "dayjs/locale/fr"

import { FitogramConfig } from "../Contexts/FitogramContext"
import useWrappedAxios from "../hooks/useWrappedAxios"
import EventTableBookingCell from "./EventTableBookingCell"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import EventBookingEditor from "./EventBookingEditor"
import { StudentBookingTool } from "./StudentBookingTool"
import EventTableBookingWindowCell from "./EventTableBookingWindowCell"
import { useEventTableFilter } from "../hooks/useEventTableFilter"
import { FitoEvent } from "../typings/FitoEvent"
import { cleanString } from "../utils/cleanString"
import {
  BOOKING_ENABLED,
  SMALL_WAITLIST,
  BOOKING_WINDOW_VISIBLE,
} from "../constants"
import { EventTableEventNameCell } from "./EventTableEventNameCell"
import { EventTableTeacherCell } from "./EventTableTeacherCell"

dayjs.extend(LocalizedFormat)
dayjs.locale("fr")

interface Props {
  classes?: boolean
  workshops?: boolean
  courses?: boolean
  eventGroupId?: number
  pageSize?: number
  from?: string
  to?: string
}

const daysOfWeekLabels = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
]

const StyledRow = styled(TableRow)`
  &&&&& {
    display: grid !important;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    @media (min-width: 768px) {
      display: table-row !important;
    }
  }
`

const EventTable: React.FC<Props> = props => {
  const { domain } = useContext(FitogramConfig)

  const {
    filterValue: teacherFilter,
    onFilterValueChange: onTeacherFilterChange,
    dataFilteringFunction: filterByTeacherName,
  } = useEventTableFilter({
    filterKey: "teacherName",
    filteringFunction: (event, filterValue) =>
      event.trainers[0] &&
      cleanString(event.trainers[0].name).includes(cleanString(filterValue)),
  })

  const {
    filterValue: eventNameFilter,
    onFilterValueChange: onEventFilterChange,
    dataFilteringFunction: filterByEventName,
  } = useEventTableFilter({
    filterKey: "eventName",
    filteringFunction: (event, filterValue) =>
      cleanString(event.name).includes(cleanString(filterValue)),
  })

  const {
    filterValue: dayOfWeekFilter,
    onFilterValueChange: onDayOfWeekFilterChange,
    dataFilteringFunction: filterByDayOfWeek,
  } = useEventTableFilter({
    filterKey: "dayOfWeek",
    filteringFunction: (event, filterValue) =>
      dayjs(event.startDateTime).day() === parseInt(filterValue, 10),
  })

  const {
    filterValue: seatsFilter,
    onFilterValueChange: onSeatsFilterChange,
    dataFilteringFunction: filterBySeats,
  } = useEventTableFilter({
    filterKey: "seats",
    filteringFunction: (event, filterValue) => {
      switch (filterValue) {
        case "notFull":
          return event.seatsBooked < event.seats
        case "full":
          return event.seatsBooked >= event.seats
        case "smallWaitList":
          return (
            event.seatsBooked >= event.seats &&
            event.seatsWaiting <= SMALL_WAITLIST
          )
        default:
          return true
      }
    },
  })

  const { eventGroupId, pageSize, from, to } = props

  const [{ data, loading, error }] = useWrappedAxios({
    url: `/providers/${domain}/events/public`,
    params: {
      // classes,
      // workshops,
      // courses,
      eventGroupId,
      pageSize,
      from,
      to,
    },
  })

  let filteredList: FitoEvent[] = []

  if (data) {
    filteredList = data
    filteredList = filterByTeacherName(filteredList)
    filteredList = filterByEventName(filteredList)
    filteredList = filterByDayOfWeek(filteredList)
    filteredList = filterBySeats(filteredList)
  }

  const filterHeaderStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      {filteredList.length > 0 && <EventBookingEditor events={filteredList} />}
      {BOOKING_ENABLED && filteredList.length > 0 && (
        <StudentBookingTool events={filteredList} />
      )}
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <div style={filterHeaderStyles}>
                <label htmlFor="search-eventName">Classe</label>
                <Input
                  placeholder="Exemple: Inter 1"
                  id="search-eventName"
                  type="search"
                  icon="search"
                  name="eventName"
                  onChange={onEventFilterChange}
                  value={eventNameFilter}
                  size="mini"
                  style={{ marginLeft: "0.5em" }}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <div style={filterHeaderStyles}>
                <label htmlFor="search-teacherName">Prof</label>
                <Input
                  placeholder="Exemple: Angèle"
                  id="search-teacherName"
                  type="search"
                  icon="search"
                  name="teacherName"
                  onChange={onTeacherFilterChange}
                  value={teacherFilter}
                  size="mini"
                  style={{ marginLeft: "0.5em" }}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell>Salle</Table.HeaderCell>
            <Table.HeaderCell>
              <div style={filterHeaderStyles}>
                <label htmlFor="search-dayofweek">Jours</label>
                <Dropdown
                  id="search-dayofweek"
                  size="mini"
                  value={dayOfWeekFilter}
                  onChange={onDayOfWeekFilterChange}
                  selection
                  style={{ marginLeft: "0.5em", fontSize: ".78571429em" }}
                  options={[
                    { key: "noValue", value: "", text: "Tous" },
                    ...daysOfWeekLabels.map((label, index) => ({
                      key: index,
                      value: index,
                      text: label,
                    })),
                  ]}
                />
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell>
              {" "}
              <div style={filterHeaderStyles}>
                <label htmlFor="search-seats">Places</label>
                <Dropdown
                  id="search-seats"
                  value={seatsFilter}
                  onChange={onSeatsFilterChange}
                  selection
                  style={{ marginLeft: "0.5em", fontSize: ".78571429em" }}
                  options={[
                    { key: "noValue", value: "", text: "Indifférent" },
                    { key: "notFull", value: "notFull", text: "Places libres" },
                    {
                      key: "smallWaitList",
                      value: "smallWaitList",
                      text: `Petite file d'attente (${SMALL_WAITLIST}-)`,
                    },
                    { key: "full", value: "full", text: "Classe pleine" },
                  ]}
                />
              </div>
            </Table.HeaderCell>
            {BOOKING_WINDOW_VISIBLE && (
              <Table.HeaderCell>Ouverture des réservations</Table.HeaderCell>
            )}
            {BOOKING_ENABLED && (
              <Table.HeaderCell>Réservation</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading &&
            Array(2)
              .fill("")
              .map((_value, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Placeholder>
                      <PlaceholderLine></PlaceholderLine>
                    </Placeholder>
                  </TableCell>
                </TableRow>
              ))}

          {error && (
            <TableRow>
              <TableCell>Erreur: {error.message}</TableCell>
            </TableRow>
          )}
          {!loading && filteredList.length === 0 && (
            <TableRow>
              <TableCell>
                <p>Pas de cours trouvé, essayer de changer les filtres.</p>
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            filteredList.map((event: FitoEvent) => {
              return (
                <StyledRow
                  key={event.uuid}
                  className="event-row"
                  disabled={event.status === "Cancelled"}
                >
                  <EventTableEventNameCell event={event} />
                  <EventTableTeacherCell event={event} />
                  <Table.Cell className="room-name">
                    {event.location && event.location.name}
                  </Table.Cell>
                  <Table.Cell className="event-time">
                    {dayjs(event.startDateTime).format("dddd DD/MM")} -{" "}
                    {dayjs(event.startDateTime).format("LT")}/
                    {dayjs(event.endDateTime).format("LT")}
                  </Table.Cell>
                  <EventTableBookingCell event={event} />
                  {BOOKING_WINDOW_VISIBLE && (
                    <EventTableBookingWindowCell event={event} />
                  )}
                  {BOOKING_ENABLED && (
                    <EventTableBookingWindowCell event={event} />
                  )}
                </StyledRow>
              )
            })}
        </Table.Body>
      </Table>
    </>
  )
}

export default EventTable
