/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useContext, useState, useEffect } from "react"
import { Formik, Field } from "formik"

import { UserContext } from "../Contexts/UserContext"
import { FitoEvent } from "../typings/FitoEvent"
import { AXIOS_CONFIG } from "../axiosConfig"
import { includes, uniqBy, filter, drop } from "lodash"
import {
  Button,
  Segment,
  Progress,
  Header,
  Dimmer,
  Loader,
  Form as SemanticForm,
  Message,
  Icon,
} from "semantic-ui-react"
import { AxiosResponse } from "axios"
import { FitogramConfig } from "../Contexts/FitogramContext"

interface FitoProduct {
  uuid: string
  id: number
  name: string
}

interface FitoOrderline {
  id: number
  uuid: string
  orderName: string
  productId: number
  productUUID: string
  text: string
}

interface Props {
  events: FitoEvent[]
}

function getEventProducts(event: FitoEvent) {
  return AXIOS_CONFIG.axios.get<FitoProduct[]>(
    `/event/${event.id}/products/public`
  )
}

function collateProducts(responses: AxiosResponse<FitoProduct[]>[]) {
  let allProducts: FitoProduct[] = []
  allProducts = responses.reduce((products, { data }) => {
    return products.concat(data)
  }, allProducts)

  return uniqBy(allProducts, product => product.uuid).map(
    product => product.uuid
  )
}

export const StudentBookingTool: React.FC<Props> = props => {
  const { user } = useContext(UserContext)
  const { domain } = useContext(FitogramConfig)

  const [isFormVisible, setFormVisibility] = useState(false)
  const [eventsToBook, setEventsToBook] = useState<FitoEvent[]>([])
  const [isProcessing, setProcessing] = useState(false)
  const [processingResults, setProcessingResults] = useState<boolean[]>([])
  const [validOrderlines, setValidOrderlines] = useState<FitoOrderline[]>([])
  const [orderlineError, setOrderlineError] = useState(false)
  const [customerId, setCustomerId] = useState<number>(null)
  const [pickedOrderLine, setPickedOrderLine] = useState<FitoOrderline["id"]>(
    null
  )

  const toggleForm = (visibility?: boolean) => () => {
    if (visibility === false) {
      setProcessing(false)
      setProcessingResults([])
      setValidOrderlines([])
      setOrderlineError(false)
    }
    setFormVisibility(visibility ? visibility : !isFormVisible)
  }

  const bookClasses = formValues => {
    setProcessingResults([])
    setPickedOrderLine(formValues.orderLine)
    setEventsToBook(props.events)
    setProcessing(true)
  }

  useEffect(() => {
    if (isFormVisible && user && validOrderlines.length === 0) {
      Promise.all(props.events.map(getEventProducts))
        .then(collateProducts)
        .then(async products => {
          const { data } = await AXIOS_CONFIG.axios.get<FitoOrderline[]>(
            `/users/current/orderlines/active?domain=${domain}&includeGlobal=false`,
            {
              headers: { Authorization: `Bearer ${user.auth_token}` },
            }
          )
          // filter out orderlines not matching products
          return filter(data, orderLine =>
            products.includes(orderLine.productUUID)
          )
        })
        .then(validOrderlines => {
          setValidOrderlines(validOrderlines)
        })
        .then(() => {
          return AXIOS_CONFIG.axios.get(
            `/providers/brussels-art-pole/customers`,
            {
              headers: { Authorization: `Bearer ${user.auth_token}` },
              params: { userUUID: user.user_uuid },
            }
          )
        })
        .then(({ data }) => {
          setCustomerId(data[0].id)
        })
        .catch(e => {
          setOrderlineError(true)
        })
    }

    if (eventsToBook.length > 0) {
      const currentEvent = eventsToBook[0]
      AXIOS_CONFIG.axios
        .post(`/bookings`, null, {
          headers: { Authorization: `Bearer ${user.auth_token}` },
          params: {
            customerId,
            orderLineId: pickedOrderLine,
            origin: "fito-shop",
            sendMail: true,
            eventId: currentEvent.id,
          },
        })
        .then(response => {
          console.log(response)
          setProcessingResults(processingResults.concat(true))
          setEventsToBook(drop(eventsToBook))
        })
        .catch(e => {
          console.log(e)
          setProcessingResults(processingResults.concat(false))
          setEventsToBook(drop(eventsToBook))
        })

        .catch()
    }
  }, [isProcessing, eventsToBook, isFormVisible, validOrderlines])

  const processSuccess =
    processingResults.length === props.events.length &&
    !includes(processingResults, false)

  const processError =
    processingResults.length === props.events.length &&
    includes(processingResults, false)

  if (!user) {
    return null
  }

  return (
    <>
      {!isFormVisible && (
        <Button content="Réserver ces classes" onClick={toggleForm(true)} />
      )}
      {isFormVisible && (
        <Segment>
          <Button
            icon="close"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 0,
              zIndex: 9999,
            }}
            size="small"
            onClick={toggleForm(false)}
          />
          {!isProcessing && (
            <>
              <Segment basic>
                <Header>
                  <p>Réservation en masse</p>
                </Header>
                <Dimmer
                  active={validOrderlines.length === 0 && !orderlineError}
                  inverted
                >
                  <Loader>Chargement des infos de réservation</Loader>
                </Dimmer>
                <Formik
                  initialValues={{
                    orderLine: 0,
                  }}
                  onSubmit={bookClasses}
                >
                  {({ isSubmitting, submitForm }) => (
                    <SemanticForm noValidate>
                      <SemanticForm.Group inline>
                        <SemanticForm.Field>
                          <label htmlFor="orderlinePicker">Carte</label>
                          <Field
                            as="select"
                            name="orderLine"
                            id="orderlinePicker"
                          >
                            <option value=""></option>
                            {validOrderlines.map(orderline => (
                              <option key={orderline.id} value={orderline.id}>
                                {orderline.text}
                              </option>
                            ))}
                          </Field>
                        </SemanticForm.Field>
                        <Button
                          primary
                          content="Réserver"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          onClick={submitForm}
                        />
                      </SemanticForm.Group>
                    </SemanticForm>
                  )}
                </Formik>
                <Message warning>
                  <Icon name="warning sign" />
                  Attention: vous allez essayer de réserver tous les cours
                  affichés dans la table ({props.events.length} au total).
                  Vérifiez les filtres avant d&apos;effectuer une action. <br />
                  Réserver des cours de plusieurs sessions à la fois n&apos;est
                  pas supporté.
                </Message>
              </Segment>
            </>
          )}
          {isProcessing && (
            <Progress
              total={props.events.length}
              value={processingResults.length}
              progress="ratio"
              style={{ marginTop: "1em" }}
              success={processSuccess}
              error={processError}
            >
              {processingResults.length < props.events.length && (
                <p>
                  Processus en cours. Ne fermez pas la boîte de dialogue ni la
                  fenêtre.
                </p>
              )}
              {processSuccess && (
                <p>
                  Tout s&apos;est bien passé! Relancez un filtre ou
                  rafraichissez la page pour voir les changements.
                </p>
              )}
              {processError && (
                <p>Des erreurs se sont produites. Veuillez Ré-essayer.</p>
              )}
            </Progress>
          )}
        </Segment>
      )}
    </>
  )
}
