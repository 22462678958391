import { useState } from "react"
import { getQueryStringValue, setQueryStringValue } from "../utils/queryString"
import { filter } from "lodash"
import { FitoEvent } from "../typings/FitoEvent"
import { InputProps, DropdownProps } from "semantic-ui-react"

interface HookParams {
  filterKey: string
  filteringFunction: (event: FitoEvent, filterValue: string) => boolean
  initialValue?: string
}

export function useEventTableFilter({
  filterKey,
  filteringFunction,
  initialValue = "",
}: HookParams) {
  const initValue =
    typeof window !== "undefined"
      ? getQueryStringValue(filterKey) || initialValue
      : initialValue

  const [filterValue, setFilterValue] = useState(initValue)

  const onFilterValueChange:
    | DropdownProps["onChange"]
    | InputProps["onChange"] = (event, data) => {
    setFilterValue(data.value)
    setQueryStringValue(filterKey, data.value)
  }

  const dataFilteringFunction = (eventsList: FitoEvent[]): FitoEvent[] => {
    if (filterValue === "") {
      return eventsList
    }
    return filter(eventsList, event =>
      filteringFunction(event, filterValue)
    ) as FitoEvent[]
  }

  return { filterValue, onFilterValueChange, dataFilteringFunction }
}
