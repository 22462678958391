import React from "react"
import dayjs from "dayjs"
import {
  Form as SemanticForm,
  Button,
  Input,
  Checkbox,
} from "semantic-ui-react"
import { Formik, useField, FormikConfig, FormikHelpers } from "formik"
import { START_TIME, END_TIME, CLASS_TYPES_FILTER } from "../constants"

export interface FormData {
  classes?: boolean
  workshops?: boolean
  courses?: boolean
  eventGroupId?: number
  pageSize?: number
  from?: string
  to?: string
}

export const initialValues: FormData = {
  classes: true,
  workshops: true,
  courses: true,
  eventGroupId: null,
  pageSize: 1000,
  from: START_TIME.startOf("day").toISOString(),
  to: END_TIME.startOf("day").toISOString(),
}

interface Props {
  onSubmit: FormikConfig<FormData>["onSubmit"]
}

const FormControlInput = props => {
  const [field] = useField(props)
  const inputId = `params-${props.name}`

  return (
    <SemanticForm.Field>
      <label htmlFor={inputId}>{props.label}</label>
      <Input id={inputId} {...field} />
    </SemanticForm.Field>
  )
}

const FormControlToggle = props => {
  const [field] = useField(props)
  const inputId = `params-${props.name}`

  const { value, ...fieldProps } = field

  return (
    <SemanticForm.Field>
      <label htmlFor={inputId}>{props.label}</label>
      <Checkbox id={inputId} toggle {...fieldProps} />
    </SemanticForm.Field>
  )
}

export const FormControlDate = props => {
  const [field, , helpers] = useField(props)

  const change = ({ target: { value } }) => {
    helpers.setValue(dayjs(value).toISOString())
  }

  return (
    <SemanticForm.Field width={3}>
      <label>
        {props.label}
        <input
          type="date"
          value={dayjs(field.value).format("YYYY-MM-DD")}
          onChange={change}
        />
      </label>
    </SemanticForm.Field>
  )
}

export const TableControls: React.FC<Props> = props => {
  async function submit(
    values: FormData,
    formikHelpers: FormikHelpers<FormData>
  ) {
    await props.onSubmit(values, formikHelpers)
    formikHelpers.setSubmitting(false)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={submit}>
      {({ isSubmitting, submitForm }) => (
        <SemanticForm noValidate>
          <SemanticForm.Group>
            {CLASS_TYPES_FILTER && (
              <>
                <FormControlToggle
                  name="classes"
                  label="Classes"
                  type="checkbox"
                />
                <FormControlToggle
                  name="workshops"
                  label="Workshops"
                  type="checkbox"
                />
                <FormControlToggle
                  name="courses"
                  label="Courses"
                  type="checkbox"
                />
              </>
            )}
            <FormControlInput
              name="pageSize"
              label="Résultats Max"
              type="number"
            />
            <FormControlDate name="from" label="Début" />
            <FormControlDate name="to" label="Fin" />
            <SemanticForm.Field>
              <label htmlFor="submit">&nbsp;</label>
              <Button id="submit" disabled={isSubmitting} onClick={submitForm}>
                Filtrer
              </Button>
            </SemanticForm.Field>
          </SemanticForm.Group>
        </SemanticForm>
      )}
    </Formik>
  )
}
