/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "../Contexts/UserContext"
import { FitoEvent } from "../typings/FitoEvent"
import {
  Segment,
  Form as SemanticForm,
  Header,
  Button,
  Progress,
  Grid,
  Message,
  Icon,
} from "semantic-ui-react"
import { Formik, Field } from "formik"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { drop, includes } from "lodash"
import { AXIOS_CONFIG } from "../axiosConfig"

dayjs.extend(customParseFormat)

interface Props {
  events: FitoEvent[]
}

const EventBookingEditor: React.FC<Props> = props => {
  const { isAdmin, user } = useContext(UserContext)

  const [isFormVisible, setFormVisibility] = useState(false)
  const [targetTime, setTargetTime] = useState(dayjs())
  const [eventsToUpdate, setEventsToUpdate] = useState<FitoEvent[]>([])
  const [eventsToReset, setEventsToReset] = useState<FitoEvent[]>([])
  const [isProcessing, setProcessing] = useState(false)
  const [processingResults, setProcessingResults] = useState<boolean[]>([])

  const toggleForm = (visibility?: boolean) => () => {
    if (visibility === false) {
      setProcessing(false)
      setProcessingResults([])
    }
    setFormVisibility(visibility ? visibility : !isFormVisible)
  }

  const changeClasses = async formValues => {
    setTargetTime(
      dayjs(
        `${formValues.targetDate} ${formValues.targetTime}`,
        "YYYY-MM-DD HH:mm"
      )
    )
    setProcessingResults([])
    setEventsToUpdate(props.events)
    setProcessing(true)
  }

  const resetEvents = async () => {
    setProcessingResults([])
    setEventsToReset(props.events)
    setProcessing(true)
  }

  useEffect(() => {
    if (eventsToUpdate.length > 0) {
      const currentEvent = eventsToUpdate[0]
      const diff = dayjs(currentEvent.startDateTime).diff(targetTime, "h")

      // Save the event
      AXIOS_CONFIG.axios
        .patch(
          `/events/${currentEvent.id}`,
          {
            enableBookingWindow: true,
            onlineRegistrationBeforeHours: `${diff}`,
            onlineRegistrationEndHours: `${0}`,
          },
          {
            headers: { Authorization: `Bearer ${user.auth_token}` },
          }
        )
        .then(response => {
          console.log(response)
          setProcessingResults(processingResults.concat(true))
          setEventsToUpdate(drop(eventsToUpdate))
        })
        .catch(e => {
          console.error(e)
          setProcessingResults(processingResults.concat(false))
          setEventsToUpdate(drop(eventsToUpdate))
        })
    }

    // Reset events
    if (eventsToReset.length > 0) {
      const currentEvent = eventsToReset[0]
      AXIOS_CONFIG.axios
        .patch(
          `/events/${currentEvent.id}`,
          {
            enableBookingWindow: false,
            onlineRegistrationBeforeHours: 0,
            onlineRegistrationEndHours: 0,
          },
          {
            headers: { Authorization: `Bearer ${user.auth_token}` },
          }
        )
        .then(response => {
          console.log(response)
          setProcessingResults(processingResults.concat(true))
          setEventsToReset(drop(eventsToReset))
        })
        .catch(e => {
          console.error(e)
          setProcessingResults(processingResults.concat(false))
          setEventsToReset(drop(eventsToReset))
        })
    }
  }, [isProcessing, eventsToUpdate, eventsToReset])

  const processSuccess =
    processingResults.length === props.events.length &&
    !includes(processingResults, false)

  const processError =
    processingResults.length === props.events.length &&
    includes(processingResults, false)

  if (!isAdmin) {
    return null
  }
  return (
    <>
      {!isFormVisible && (
        <Button
          content={"Modifier les heures de réservation"}
          onClick={toggleForm(true)}
        />
      )}
      {isFormVisible && (
        <Segment>
          <Button
            icon="close"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 0,
              zIndex: 999,
            }}
            size="small"
            onClick={toggleForm(false)}
          />
          {!isProcessing && (
            <>
              <Grid columns={2} stackable textAlign="center" divided>
                <Grid.Row>
                  <Grid.Column>
                    <Segment basic>
                      <Header>
                        <p>Ouvrir les réservations pour une date précise</p>
                      </Header>
                      <Formik
                        initialValues={{
                          targetDate: dayjs().format("YYYY-MM-DD"),
                          targetTime: "12:00",
                        }}
                        onSubmit={changeClasses}
                      >
                        {({ isSubmitting, submitForm }) => (
                          <SemanticForm noValidate>
                            <SemanticForm.Group inline>
                              <div style={{ flex: 1 }}></div>
                              <SemanticForm.Field>
                                <label htmlFor="targetDate">Jour</label>
                                <Field
                                  type="date"
                                  name="targetDate"
                                  id="targetDate"
                                />
                              </SemanticForm.Field>
                              <SemanticForm.Field>
                                <label htmlFor="targetTime">Heure</label>
                                <Field
                                  type="time"
                                  name="targetTime"
                                  id="targetTime"
                                />
                              </SemanticForm.Field>
                              <Button
                                primary
                                content="Enregistrer"
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                onClick={submitForm}
                              />
                              <div style={{ flex: 1 }}></div>
                            </SemanticForm.Group>
                          </SemanticForm>
                        )}
                      </Formik>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment basic>
                      <Header>
                        <p>Retirer la fenêtre de réservation</p>
                      </Header>
                      <Formik onSubmit={resetEvents} initialValues={{}}>
                        {({ submitForm }) => (
                          <SemanticForm noValidate>
                            <SemanticForm.Group inline>
                              <div style={{ flex: 1 }}></div>
                              <Button
                                negative
                                content="Reset"
                                loading={isProcessing}
                                disabled={isProcessing}
                                onClick={submitForm}
                              />
                              <div style={{ flex: 1 }}></div>
                            </SemanticForm.Group>
                          </SemanticForm>
                        )}
                      </Formik>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns={1} textAlign="center">
                <Grid.Row>
                  <Grid.Column>
                    <Message warning>
                      <Icon name="warning sign" />
                      Attention: les changements s&apos;appliqueront à tous les
                      évènements visibles dans la table. Vérifiez les filtres
                      avant d&apos;effectuer une action.
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          )}
          {isProcessing && (
            <Progress
              total={props.events.length}
              value={processingResults.length}
              progress="ratio"
              style={{ marginTop: "1em" }}
              success={processSuccess}
              error={processError}
            >
              {processingResults.length < props.events.length && (
                <p>
                  Processus en cours. Ne fermez pas la boîte de dialogue ni la
                  fenêtre.
                </p>
              )}
              {processSuccess && (
                <p>
                  Tout s&apos;est bien passé! Relancez un filtre ou
                  rafraichissez la page pour voir les changements.
                </p>
              )}
              {processError && (
                <p>Des erreurs se sont produites. Veuillez Ré-essayer.</p>
              )}
            </Progress>
          )}
        </Segment>
      )}
    </>
  )
}

export default EventBookingEditor
